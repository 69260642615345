.nav {
    position: relative;
}

.nav-fixed {
    position: fixed;
    top: 0; left: 0; 
    z-index: 1000; 
}

.nav-fixed,
.nav-shim {
    width: 100%; height: 5rem; /* the height of the nav menu */
    line-height: 5rem; /* force the buttons to vertically center by making line-height == height */
    text-align: center;

    background: rgb(45, 0, 50) url("../assets/images/masthead.jpg");
    background-position: 0px 0px; 
	background-size: 100% 330px;
    background-repeat: no-repeat;
    background-attachment: local;
}

@media (max-width: 1441px) {
    .nav-fixed {
        background-size: 1440px 330px; /* Fixed size for larger screens */
        background-position: center top; /* Adjust position as needed */
    }
}

.nav-item {
    margin: 0rem 1rem;
}

.nav-item .label {
    margin: 0.5rem 0;
}

.nav-item .indicator,
.indicator:active,
a:hover .indicator {
    width: 100%; height: 1px;
}

.nav-item .indicator {
    visibility: hidden;
    margin: auto;
    width: 1%; height: 1px;
    background-color: white;
    border-radius: 1px;
    box-shadow: 0px 0px 20px 2px white;
    transition: all .25s ease-in-out;
}

.nav-item .indicator:active,
a:hover .nav-item .indicator {
    visibility: visible;
}
