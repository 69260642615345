.page {
    background-color: gray;
}

.anchor {
    position: relative;
    width: 100%;
}

.anchor-offset {
    position: absolute;
    top: -6.5rem; /* the height of the nav bar */
    width: 100%;
}

.title {
    border: none !important;
}

.subtitle {
    font-family: 'Heebo', sans-serif;
    margin: 0;
    opacity: 0.6;
    font-weight: normal;
    text-align: center;
    font-size: 2rem;
    font-weight: 200;
    color: rgb(22, 0, 128)
}

.pronunciation {
    font-family: 'Heebo', sans-serif;
    margin: 1rem 0;
    opacity: 0.6;
    text-align: left;
    font-size: 1.2rem;
    font-weight: 400;
    color: rgb(22, 0, 128)
}

.article {
    overflow-x: auto;
}

#login-widget {
   max-width: 30rem;
   margin: 0 auto;
}