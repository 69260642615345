/********* FONTS *********/
/*font-family: "Source Sans Pro", Tahoma, sans-serif;*/
@font-face {
    font-family: 'great_vibesregular';
    src: url('../assets/fonts/greatvibes-regular-webfont.woff2') format('woff2'),
         url('../assets/fonts/greatvibes-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Heebo';
    src: url('../assets/fonts/Heebo-Light.woff2') format('woff2'),
        url('../assets/fonts/Heebo-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Heebo';
    src: url('../assets/fonts/Heebo-Thin.woff2') format('woff2'),
        url('../assets/fonts/Heebo-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Heebo';
    src: url('../assets/fonts/Heebo-Black.woff2') format('woff2'),
        url('../assets/fonts/Heebo-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Heebo';
    src: url('../assets/fonts/Heebo-ExtraBold.woff2') format('woff2'),
        url('../assets/fonts/Heebo-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Heebo';
    src: url('../assets/fonts/Heebo-SemiBold.woff2') format('woff2'),
        url('../assets/fonts/Heebo-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Heebo';
    src: url('../assets/fonts/Heebo-Bold.woff2') format('woff2'),
        url('../assets/fonts/Heebo-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Heebo';
    src: url('../assets/fonts/Heebo-ExtraLight.woff2') format('woff2'),
        url('../assets/fonts/Heebo-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Heebo';
    src: url('../assets/fonts/Heebo-Regular.woff2') format('woff2'),
        url('../assets/fonts/Heebo-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Heebo';
    src: url('../assets/fonts/Heebo-Medium.woff2') format('woff2'),
        url('../assets/fonts/Heebo-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

html {
	overflow-x: hidden;
	overflow-y: auto;
	overscroll-behavior: none;
	-webkit-overflow-scrolling: touch; /* enables “momentum” (smooth) scrolling */
}

.center-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    width: 100%;
}

.masthead {
    position: relative;
    box-sizing: border-box;
    width: 100%; 

	/* 5rem is the height of the nav menu - see nav.css */
	height: 250px; /* Fallback in case calc fails */
    height: calc(330px - 5rem); 
	height: -webkit-calc(330px - 5rem);
	height: -moz-calc(330px - 5rem);

    background: rgb(45, 0, 50) url("../assets/images/masthead.jpg"); 
    background-position: 0px 0px; 
	background-size: 100% 330px;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

@media (max-width: 1441px) {
    .masthead {
        background-size: 1440px 330px; /* Fixed size for larger screens */
        background-position: center top; /* Adjust position as needed */
    }
}

/* CSS specific to iOS devices */ 
@supports (-webkit-touch-callout: none) {
    .masthead {
	    background-position: 0px -5rem;
        background-attachment: scroll; /* iOS does not support fixed because of performance */
    }
}

.logo {
    visibility: visible;
    position: relative;
    z-index: 1001;
    height: 100%;
    padding-top: 2rem;
    padding-bottom: 4.5rem;
    pointer-events: none;
    opacity: 1;
}

.logo .img {
    width: 100%; height: 100%;
    background: transparent url("../assets/images/logo.png"); 
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.announcement-title {
    margin-bottom: 0;
}

.slogan {
    margin-bottom: 3rem;
}

.enthusiasm {
    font-family: 'great_vibesregular', sans-serif !important;
    font-size: 3.2rem;
    width: 100%;
    text-align: center;
    color: rgb(22, 0, 128)
}

.overview-content {
    padding: .25rem 0 ;
}

/*
.notice {
    color: #9b98b3;
}
*/

.ui.table tr td { 
    border-top: 0px !important; 
}

.ui.table tr td p { 
    display: list-item;
    list-style-type: disc;
    list-style-position: outside;
}

.lesson-features tr td {
    color: rgb(92, 0, 133);
}

.about {
    margin: 0; padding: 0;
}

figure.about figcaption {
    font-family: 'FranklinITCProLight', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif; 
    font-size: .9rem;
    color: #737391;
}

.file-version {
    width: 100%;
    text-align: center;
}

.footer {
    padding: 2rem 0;
}

.left-border {
    border-left: 1px solid lightgray;
}
