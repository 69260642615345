.price {
    margin: 0; padding: 0;
    margin-bottom: 0.5rem;
    font-size: 2rem;
    font-weight: normal;
    color: rgb(22, 0, 128);
    text-align: center;
}

.term {
    margin: 0; padding: 0;
    line-height: 1.1rem;
    text-align: center;
}

.offer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
}